import React from 'react';

function About() {
    return (
        <>
            <div className="box-set">
                <main>
                    <section className="body-section definition">
                        <h1>About Khaos<sup className="h3 a-alt2"><a href="#Disclaimer">*</a></sup></h1>
                    </section>
                    <section className="swatch-2 body-section around-here">
                        <h2>Around here...</h2>
                        <div className="values">
                            <section className="">
                                <h3>Our Beliefs Shape our Work</h3>
                                <p className="p-2">
                                    <br/>
                                    In this business, the “why” is just as important as the “what”, so we hold ourselves accountable to more than just our clients’ standards. 
                                    <br/><br/>
                                    We hold ourselves to our own ideals as well.
                                </p>
                            </section>
                            <section className="">
                                <h3>Silos are Dead</h3>
                                <p className="p-2">
                                    <br/>
                                    Interwoven solutions are no longer assets, they’re the norm. 
                                    <br/><br/>
                                    To consider a single deliverable as a solution is to underestimate the ecosystem and undervalue the audience. 
                                </p>
                            </section>
                            <section className="">
                                <h3>Culture is King</h3>
                                <p className="p-2">
                                    <br/>   
                                    It takes more than a cheque and a challenge to get us involved.
                                    <br/><br/>
                                    If we’re going to sweat for your brand, then we need to believe in it.
                                </p>
                            </section>
                            <section>
                                <h3>Digital Experiences = Human Experiences</h3>
                                <p className="p-2">
                                    <br/>
                                    Consider Google’s entire <a href="https://killedbygoogle.com/" target="blank" className="a-alt">graveyard</a> of duds. Now, recall all of their most respected <a href="https://about.google/intl/en_us/products/?tip=hands-free" target="blank">creations.</a> 
                                    <br/><br/>
                                    Success begins and ends with user delight, so our work does too. 
                                </p>
                            </section>
                            <section>
                                <h3>Fuelled by Iteration</h3>
                                <p className="p-2">
                                    <br/>
                                    We experiment incessantly, constantly refining our processes and knowledgebase.
                                    <br/><br/>
                                    We didn’t become experts by resting on our laurels. 
                                </p>
                            </section>
                        </div>
                    </section>
                    <aside className="main-section disclaimer swatch-4 odd">
                        <h4 className="font-1" id="Disclaimer"><strong><sup>*</sup>Disclaimer</strong></h4>
                        <p className="p-2">
                            Our name comes from the first primoridal god in Greek Mythology “Khaos” who produced the Island of Creation where all things come into being.
                            <br/><br/>
                            
                            We believe all good creative work is an act of creation which requires the spark of chaos to achieve anything new.
                            However, unrestrained chaos isn’t sustainable, order is needed for the universe to function properly.
                            <br/><br/>
                            We consume the chaos that is inherent to our industry, and craft content that seeks to bring growth and order to our clients’ businesses. 

                            <br/><br/>
                            And forigve us for being Greek myth nerds...
                        </p>
                    </aside>
                </main>
            </div>
        </>
    )
}

export default About;