import React from 'react';
import { Link } from 'react-router-dom';

function CallToAction() {
  return (
    <>
      <div className="swatch-3 main-section design-guide-section ">
           <aside className="">
               <h3 className="non-em" >
                   Start hitting <strong>milestones</strong><br/>instead of <strong>dead zones</strong>
               </h3>
               <br/>
                <h3>
                    <Link to="/Contact" className="btn3">Get in touch</Link>
                </h3>
            </aside>
        </div>
    </>
  );
}

export default CallToAction;
