import React from 'react';
import ReactDOM from 'react-dom/client';
import './resources/css/styles.css';
import {
  BrowserRouter as Router, Routes,
  Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import './resources/css/styles.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import BrandAudit from './Pages/BrandAudit';
import CaseStudy from './Components/CaseStudy';
import CaseStudies from './Pages/CaseStudies';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <body className="swatch-3">
    <Router>
      <ScrollToTop />
      <Header />
      <div className="swatch-3">
        <Routes>
          <Route path='/' element={<Home />} exact />
          <Route path='/about' element={<About />} exact />
          <Route path='/services' element={<Services />} exact />
          <Route path='/contact' element={<Contact />} exact />
          <Route path='/case-studies/:name' element={<CaseStudy />} />
          <Route path='/case-studies' element={<CaseStudies />} />
          <Route path='/brand-audit' element={<BrandAudit />} />
        </Routes>
      </div>
      <Footer />
    </Router>
    </body>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
