import React/*, { useState }*/ from 'react';

function Contact() {
   /* const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.email.trim() === '' || formData.name.trim() === '' || formData.message.trim() === '') {
            setError('Please fill out all fields.');
            return;
        }
        // Mock sending function: replace this with actual API call or email sending logic
        console.log('Sending form data...', formData);
        setSubmitted(true);
        setError('');
        // Reset form data after submission
        setFormData({ name: '', email: '', message: '' });
    };*/

    return (
        <>
            <div className="box-set">
                <main className="main-section odd design-guide-section">
                    <h1>Say hello</h1>
                    <h3>We want to hear from changemakers like you.</h3>
                    <br/><br/>
                    <h3>
                        Sends us an email at <a href="mailto:contact@createkhaos.com?subject=Getting%20in%20Touch%20with%20Khaos">contact@createkhaos.com</a> touch to share a goal, a challenge, or simply build your network.
                    </h3>
                   {/* <form onSubmit={handleSubmit} className="swatch-2 main-section design-guide-section odd">
                        <input type="hidden" name="source" value="Ramen"/>
                        <section className="Name">
                            <input type="text" placeholder="Name" name="name" id="name"
                                   value={formData.name} onChange={handleChange} />
                        </section>
                        <section className="Email">
                            <input type="email" placeholder="Email" name="email" id="email"
                                   value={formData.email} onChange={handleChange} required />
                        </section>
                        <section className="Message">
                            <textarea placeholder="How can we help you?" name="message" id="message"
                                      value={formData.message} onChange={handleChange} rows="10"></textarea>
                        </section>
                        <section className="submit-btn" id="form-submit">
                            <input type="submit" value="Send" className="btn a-alt2" data-wait="Please wait..."/>
                        </section>
                        {error && (
                            <aside className="ramen-form-fail ramen-form">
                                <p>{error}</p>
                            </aside>
                        )}
                        {submitted && (
                            <aside className="ramen-form-done ramen-form swatch-1 main-section design-guide-section">
                                <p>Thank you, your submission has been received! Someone from our team will get back to you within 24 hours.</p>
                            </aside>
                        )}
                    </form>
                        */}
                </main>
            </div>
        </>
    );
}

export default Contact;