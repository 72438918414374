import React from 'react';
import CallToActionAudit from '../Components/CallToActionAudit';

function BrandAudit() {


    return (
        <>
            <div className="box-set">
                <main className="index body-set case-study">
                    <section className="intro main-section swatch-3 design-guide-section">
                        <h2>Brand Audit</h2>
                        <h4>
                            For those unsure of where to start
                        </h4>
                    </section>
                    <section className="about main-section swatch-1 design-guide-section">
                        <h3 className="about">
                            It's <em>really</em> easy to spend money on creative ideas...<br/><br/>
                            Getting results for your business, is a different story.
                        </h3>
                    </section>
                    <section className="about main-section swatch-2 odd design-guide-section">
                        <h3>
                           That's why at Khaos, we like to start with a Brand Audit
                        </h3>
                        <p>
                            It's a 3 week process where we do a deep-dive into
                            <ul>
                               <li className="bulleted">
                                    Where have your past marketing initiatives succeeded and/or failed?
                                </li>
                                <li className="bulleted">
                                    What are your team's marketing strengths and weaknesses?
                                </li>
                                <li className="bulleted">
                                    Who do you want to target?
                                </li>
                                <li className="bulleted">
                                    Why should your audience should believe in you?
                                </li>
                                <li className="bulleted">
                                    How to best move forward?
                                </li>
                            </ul>
                            We do this using a capacity building approach. The purpose isn’t for you to rely on us but to help identify how you can optimize your team’s strengths and avoid the pitfalls of where they fall short.
                        </p>
                        </section>
                        <section className="about main-section swatch-4 design-guide-section">
                        <h3>The Details</h3>
                        <p>We start with an Initial Meeting with all relevant Stakeholders. This is where we begin to poke and prod to get a sense of what makes your team tick. We also make sure you've given us access to everything we're going to need.</p>
                        <p>After that Initial Meeting is when we dive in. From Brand Guides to User Research to Past Campaigns Results and even your front-end code, we're going to examine everything we get our hands-on.</p>
                        <p>A few days into that process, our Lead will check in with your point person to share preliminary thoughts and request anything else that seemed to be missing from the initial brain dump.</p>
                        <p>From there, our team puts together the Brand Audit into a digital document, approximately 20-30 pages in length. The Brand Audit include:
                        <ul>
                            <li className="bulleted">
                                what we've found
                            </li>
                            <li className="bulleted">
                                what we'd recommend
                            </li>
                        </ul>
                        You can take it and implement it yourselves, or we can do it for you.</p>
                        <p>Our fee is $1,500.</p>
                        <p>If you decide to work with us on any followup work, we deduct the Brand Audit fee from that next project's budget. Our usual projects range from 5k-30k while our monthly services start at 3k/month. </p>
                    </section>
                    <section className="process main-section swatch-3 design-guide-section odd">
                        <h2 className="process">
                            Timeline<sup className="h3 a-alt2">*</sup>
                        </h2>
                        <ul className="bulleted">
                            <h3>Week 1 - Monday</h3>
                            <li className="bulleted">Initial Meeting <br/>(~1 hour 15  minutes)</li>
                            <h3>Week 1 - Friday</h3>
                            <li className="bulleted">Khaos Lead touches base with your point person <br/>(~30 minutes)</li>
                            <h3>Week 2 – Thursday</h3>
                            <li className="bulleted">Khaos Team delivers the Brand Audit</li>
                            <h3>Week 3 – Tuesday</h3>
                            <li className="bulleted">Final Meeting with the Khaos team highlighting key takeaways and suggested next steps <br/>(~45 minutes) </li>
                        </ul>
                        <h6>
                            <sup>*</sup>Exact schedule may vary based on scheduling
                        </h6>
                    </section>
                <CallToActionAudit/>
                </main>
            </div>
        </>
    )
}

export default BrandAudit;